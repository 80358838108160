import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIProveedores } from "../../../../services/api/facturas";
import ConfirmModal from "../shared/components/ConfirmModal";
import { APImovimientos } from "./../../../../services/api/facturas";

export default function BackOffice() {

    const [selectedProvNombre, setSelectedProvNombre] = useState(null);
    const [selectedProveedor, setSelectedProveedor] = useState(null);
    const [selectedProveedorData, setSelectedProveedorData] = useState(null);
    const [showFields, setShowFields] = useState(false);
    const [selectedTiposFactura, setSelectedTiposFactura] = useState(null);
    const [tiposFactura, setTiposFactura] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const {
        control,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm({});

    const [editDescription, setEditDescription] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newCuenta, setNewCuenta] = useState('');

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedProveedor(newValue ? newValue.id : null);
        setSelectedProveedorData(newValue);
        setShowFields(!!newValue); // Mostrar los campos si se selecciona un proveedor
    };

    const handleSaveDescription = async (tipo) => {
        // Aquí puedes implementar la lógica para guardar los cambios en la descripción
        if (!tipo || !tipo.nuevaDesc || tipo.nuevaDesc.trim() === "") {
            showErrorToast('Debe introducir una nueva descripción válida');
            return;
        }
        try {
            const res = await APIProveedores.put({ tipo: { id: tipo.id, description: tipo.nuevaDesc, cuenta: tipo.nuevaCuenta } });
            showToast('Se ha modificado el tipo de factura');
            fetchDataTipoFacturas();
            setEditDescription('');
        } catch (error) {
            showErrorToast('No se ha podido modificar el tipo de factura');
        }
    };

    const handleSaveProveedor = async (id) => {
        var posible = document.getElementById("posible").value;
        var nombre = document.getElementById("nombre").value;
        var cifnif = document.getElementById("cifnif").value;

        if (!posible || posible.trim() === "") {
            showErrorToast('Debe introducir un posible válido');
            return;
        }

        if (!nombre || nombre.trim() === "") {
            showErrorToast('Debe introducir un nombre válido');
            return;
        }

        if (!cifnif || cifnif.trim() === "") {
            showErrorToast('Debe introducir un cifnif válido');
            return;
        }

        let data = {id: id, posible: posible, nombre: nombre, cifnif: cifnif};

        try {
            await APIProveedores.updateProveedorData({data});
            showToast('Se ha modificado el proveedor');
            fetchDataProveedores();
        } catch (error) {
            showErrorToast('No se ha podido modificar el proveedor');
        }
    };

    const fetchDataProveedores = async () => {
        const data = await APIProveedores.getAllProveedoresSaved();
        setSelectedProvNombre(data.proveedores);
    };

    const fetchDataTipoFacturas = async () => {
        let data = await APIProveedores.get();
        setTiposFactura(data.tiposProvs);
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    const buttonStyles = {
        backgroundColor: '#215732',
        borderRadius: '4px',
        boxShadow: 'none',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        padding: '8px 18px',
    };

    useEffect(() => {
        fetchDataProveedores();
        fetchDataTipoFacturas();
    }, []);

    function flattenArray(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return arr.reduce((acc, val) => acc.concat(val), []);
    }

    const handleCierre = () => {
        setConfirmationModalOpen(true);
    };

    const handleModalCierre = async (confirm) => {
        setConfirmationModalOpen(false);
        if (confirm) {
            try {
                const res = await APImovimientos.cierre();;
                showToast('Se han cerrado: ' + res.resultado + ' movimiento/s');
            } catch (error) {
                showErrorToast('No se ha podido ejecutar el cierre, pruebe más tarde');
            }
        } else {
            return;
        }
    };

    const handleCancelCierre = () => {
        setConfirmationModalOpen(false);
    };

    const handleAddTipoFactura = async () => {
        if (!newDescription || newDescription.trim() === "") {
            showErrorToast('Debe introducir una descripción válida');
            return;
        }
        if (!newCuenta || newCuenta.trim() === "") {
            showErrorToast('Debe introducir una cuenta válida');
            return;
        }
        try {
            const res = await APIProveedores.post({ description: newDescription, cuenta: newCuenta });
            showToast('Se ha creado un nuevo tipo de factura');
            setNewDescription(null);
            setNewCuenta(null);
            fetchDataTipoFacturas(); // Actualizar los datos de tipos de factura después de crear uno nuevo
        } catch (error) {
            showErrorToast('No se ha podido crear un nuevo tipo de factura');
        }
    };

    const customWidth = {
        width: '18%',
        display: 'inline-block',
        paddingRight: '10px'
    };

    const customWidthData = {
        width: '30%',
        display: 'inline-block',
        paddingRight: '10px'
    };

    return (
        <div className="content pt-3">
            <ToastContainer />
            <h1>BackOffice</h1>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs">
                <Tab label="Datos de proveedor" />
                <Tab label="Tipos de factura" />
                <Tab label="Cierre de movimentos y facturas" />
            </Tabs>
            <div style={{ padding: "20px", border: "1px solid #ccc", borderRadius: "5px" }}>
                {selectedTab === 0 && (
                    <div className="flex flex-row flex-1" style={customWidthData}>
                        <div className="flex flex-col">
                            <label htmlFor="prov_map_proveedor_id" style={{ marginBottom: "10px", display: "flex" }}>Selecciona un Proveedor</label>
                            <Autocomplete
                                options={flattenArray(selectedProvNombre)} // Reemplaza 'flattenArray(selectedProvNombre)' con tus opciones
                                getOptionLabel={(option) => option.nombre} // Reemplaza 'nombre' con el campo relevante de tu objeto de opción
                                onChange={handleAutocompleteChange}
                                value={flattenArray(selectedProvNombre).find((item) => item.id === selectedProveedor) || null}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                noOptionsText="No hay opciones para los criterios introducidos"
                            />
                        </div>
                        {showFields && (
                            <><div className="form-group" style={{ marginTop: "1rem" }}>
                                <h4 htmlFor="prov_map_proveedor_id" style={{ marginBottom: "10px", display: "flex" }}>Selecciona un Proveedor</h4>
                                <label>Posible: {selectedProveedorData.posible}</label>
                                <input id='posible' type='text'></input>
                                <label>Nombre: {selectedProveedorData.nombre}</label>
                                <input id='nombre' type='text'></input>
                                <label>CifNif: {selectedProveedorData.cifnif}</label>
                                <input id='cifnif' type='text'></input>
                            </div>
                                <div>
                                    <button style={buttonStyles} onClick={() => handleSaveProveedor(selectedProveedorData.id)}>Guardar</button>
                                </div></>
                        )}
                    </div>
                )}
                {selectedTab === 1 && (
                    <div className="mt-1 table-responsive">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <input type="text" placeholder="Descripción Tipo Factura" value={newDescription} onChange={(e) => setNewDescription(e.target.value)} />
                                <input type="text" placeholder="Cuenta" value={newCuenta} onChange={(e) => setNewCuenta(e.target.value)} />
                                <button style={{ width: "14rem", marginTop: "0rem" }} className="btn btn-primary" onClick={handleAddTipoFactura}>
                                    Nuevo Tipo Factura
                                </button>
                            </div>
                        </div>
                        <table className="mt-1 table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Valor Actual</th>
                                    <th>Nuevo Valor</th>
                                    <th>Cuenta Actual</th>
                                    <th>Cuenta Nuevo Valor</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tiposFactura.map(tipo => (
                                    <tr key={tipo.id}>
                                        <td>{tipo.id}</td>
                                        <td>
                                            <div>
                                                <span>
                                                    {tipo.description}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="text"
                                                    value={tipo.nuevaDesc}
                                                    onChange={(e) => {
                                                        const newTiposFactura = tiposFactura.map(item =>
                                                            item.id === tipo.id ? { ...item, nuevaDesc: e.target.value } : item
                                                        );
                                                        setTiposFactura(newTiposFactura);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <span>
                                                    {tipo.cuenta}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="text"
                                                    value={tipo.nuevaCuenta}
                                                    onChange={(e) => {
                                                        const newTiposFactura = tiposFactura.map(item =>
                                                            item.id === tipo.id ? { ...item, nuevaCuenta: e.target.value } : item
                                                        );
                                                        setTiposFactura(newTiposFactura);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <button style={buttonStyles} onClick={() => handleSaveDescription(tipo)}>Guardar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {selectedTab === 2 && (
                    <div className="form-group" style={{ marginBottom: "20px" }}>
                        <Alert severity="info">Pulse el botón para cerrar movimientos y facturas</Alert>
                        <button style={{ width: "14rem", marginTop: "1rem" }}
                            className="btn btn-success"
                            onClick={handleCierre}
                        >
                            Ejecutar Cierre
                        </button>
                    </div>
                )}
                {confirmationModalOpen && (
                    <ConfirmModal
                        title="Confirmar cierre"
                        description="¿Seguro que desea ejecutar el cierre?"
                        state={confirmationModalOpen}
                        onConfirmAction={handleModalCierre}
                        onClose={handleCancelCierre}
                    />
                )}
            </div>
        </div>
    );
}