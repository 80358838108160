export const SidebarData = [
    {
        title: 'Movimientos',
        path: '/movimientos',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Facturas a crédito',
        path: '/facturas',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Abonos',
        path: '/abonos',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Exportadores',
        path: '/exportadores',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'BackOffice',
        path: '/backoffice',
        cName: 'nav-text',
        permission: 0
    }
]