import { headers } from '../../../views/shared/helpers/functionalities';
import API from '../index';

export const APImovimientos = {
    get: async (limit, page, ftrParams) => {
        const url = `/movimientos?limit=${limit}&page=${page}&${ftrParams}`;
        return await API.get(url).then(res => res.data);
    },
    saveMovimientos: async (body) => {
        return await API.post('/movimientos', body).then(res => res.data);
    },
    post: async (body) => {
        return await API.post('/movimientos/getMovimientosFiltered', body).then(res => res.data);
    },
    performNota: async (body) => {
        return await API.post('/movimientos/performNota', body).then(res => res.data);
    },
    put: async (body) => {
        return await API.put('/movimientos', body).then(res => res.data);
    },
    delete: async (body) => {
        return await API.delete('/movimientos', body).then(res => res.data);
    },
    delete: async (id) => {
        return await API.delete('/movimientos?movimientoId=' + id).then(res => res.data);
    },
    cierre: async () => {
        return await API.get('/movimientos/cierre').then(res => res.data);
    },
}

export const APIIncidencias = {
    get: async (provId, page) => {
        return await API.get(`/incidencias?provId=${provId}`).then(res => res.data);
    },
    saveIncidencia: async (body) => {
        return await API.post('/incidencias', body).then(res => res.data);
    },
    post: async (body) => {
        return await API.post('/incidencias/modifyIncidencias', body).then(res => res.data);
    },
}

export const APIexcel = {
    post: async (documento) => {
        return await API.post('/movimientos/process', documento, headers).then(res => res.data);
    }
}

export const APIfacturas = {
    get: async (id) => {
        return await API.get(`/facturas/list?movid=${id}`).then(res => res.data);
    },
    listProveedores: async () => {
        return await API.get(`/facturas/listProveedores`).then(res => res.data);
    },
    listProveedoresCredit: async () => {
        return await API.get(`/facturas/listProveedoresCredit`).then(res => res.data);
    },
    listNoMov: async (body) => {
        return await API.post(`/facturas/listFacturasNoMov`, body).then(res => res.data);
    },
    post: async (documento) => {
        return await API.post('/facturas/nueva', documento, headers)
    },
    postFacturaNoMov: async (documento) => {
        return await API.post('/facturas/nuevaFactNoMov', documento, headers)
    },
    putFacturaNoMov: async (documento) => {
        return await API.post('/facturas/updateFactNoMov', documento, headers)
    },
    delete: async (id) => {
        return await API.delete('/facturas?facturaid=' + id).then(res => res.data);
    },
    download: async (documentoId) => {
        return await API.get(`/facturas/download?facturaid=${documentoId}`, { responseType: 'blob' }).then(res => res.data);
    },
    viewFactura: async (id) => {
        return await API.get(`/facturas/viewFactura?facturaid=${id}`, { responseType: 'blob' }).then(res => res.data);
    }
}

export const APIAbonos = {
    get: async (id) => {
        return await API.get(`/abonos/list?movid=${id}`).then(res => res.data);
    },
    listProveedores: async () => {
        return await API.get(`/abonos/listProveedores`).then(res => res.data);
    },
    listProveedoresCredit: async () => {
        return await API.get(`/abonos/listProveedoresCredit`).then(res => res.data);
    },
    listNoMov: async (body) => {
        return await API.post(`/abonos/listAbonosNoMov`, body).then(res => res.data);
    },
    post: async (documento) => {
        return await API.post('/abonos/nuevo', documento, headers)
    },
    postAbonoNoMov: async (documento) => {
        return await API.post('/abonos/nuevoAbonoNoMov', documento, headers)
    },
    putAbonoNoMov: async (documento) => {
        return await API.post('/abonos/updateAbonoNoMov', documento, headers)
    },
    delete: async (id) => {
        return await API.delete('/abonos?abonoid=' + id).then(res => res.data);
    },
    download: async (documentoId) => {
        return await API.get(`/abonos/download?abonoid=${documentoId}`, { responseType: 'blob' }).then(res => res.data);
    },
    viewAbono: async (id) => {
        return await API.get(`/abonos/viewAbono?abonoid=${id}`, { responseType: 'blob' }).then(res => res.data);
    }
}

export const APIOcr = {
    post: async (documento) => {
        return await API.post('/ocr', documento, headers).then(res => res.data);
    }
}

export const APIproveedoresRelationships = {
    post: async (body) => {
        return await API.post('/prov/insert', body).then(res => res.data);
    }
}

export const APIProveedores = {
    get: async () => {
        return await API.get(`/prov/getTiposFactura`).then(res => res.data);
    },
    getAllProveedores: async () => {
        return await API.get(`/prov/getAllProveedores`).then(res => res.data);
    },
    getAllProveedoresSaved: async () => {
        return await API.get(`/prov/getAllProveedoresSaved`).then(res => res.data);
    },
    post: async (body) => {
        return await API.post(`/prov/createTipoFactura`, body).then(res => res.data);
    },
    put: async (body) => {
        return await API.put(`/prov/saveTipoFactura`, body).then(res => res.data);
    },
    updateProveedorData: async (body) => {
        return await API.put(`/prov/updateProveedorData`, body).then(res => res.data);
    },
}