import React, { useEffect, useState } from 'react';
import { APIAbonos } from "../../../../services/api/facturas";


const ViewerAbono = ({ abonoId }) => {
  const [fileContent, setFileContent] = useState(null);
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blob = await APIAbonos.viewAbono(abonoId);
        const contentType = blob.type;
        setFileContent(blob);
        setFileType(contentType);
      } catch (error) {
        console.error('Error al obtener el documento del abono:', error);
        // Manejar el error según tus necesidades
      }
    };

    fetchData();
  }, [abonoId]);

  if (!fileContent || !fileType) {
    return <p>Cargando...</p>;
  }

  const fileUrl = URL.createObjectURL(fileContent);

  return (
    <div>
      <iframe
        title="Abono PDF"
        width="100%"
        height="600"
        src={fileUrl}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default ViewerAbono;
