import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import './styles/main.scss';
import Login from './views/Login';
import Main from './views/Main';
import useToken from './useToken';
import { logout } from "./services/auth";
import './styles/main.scss';

function App() {
  const navigate = useNavigate();
  const { token, setToken } = useToken();

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    navigate('/login', { replace: true });
  }


  return (
    <Routes>
      <Route path="/" element={
          !token ? 
            <Login setToken={setToken} /> 
          : <Main onLogout={handleLogout} />
        }
      ></Route>

      <Route path="/login" element={
          !token ? 
            <Login setToken={setToken} /> 
          : <Main onLogout={handleLogout} />
        }
      ></Route>

      <Route path='*' exact={true} element={
        !token ? 
            <Login setToken={setToken} /> 
          : <Main onLogout={handleLogout} />
      } />
      <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
    </Routes>
  );
}

export default App;
