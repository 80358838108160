import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { processFactura, insertNuevaRelacion, saveMovs, APImovimientos, APIexcel, APIproveedoresRelationships } from "../../../../../../services/api/facturas";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { format } from 'date-fns'
import save from "../../../../../../images/save.png"
import ConfirmModal from "../../../shared/components/ConfirmModal";

export default function Step1({
    onExcelsUploaded
}) {

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
    });

    const navigate = useNavigate();
    const [movements, setMovements] = useState([]);
    const [tipoCuenta, setTipoCuenta] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setIsLoading] = useState(false);
    const [saveDialog, setSaveDialog] = useState(false);
    const [rowSelected, setRowSelected] = useState(null);
    const [tiposProv, setTiposProv] = useState([]);
    const tiposIva = [{ label: "0%", value: 0 }, { label: "10%", value: 10 }, { label: "21%", value: 21 }]

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const documentoForm = new FormData();

        Object.entries(data).forEach((value) => {
            if ((value[0] === 'documento') && value[1]?.length > 0) {
                documentoForm.append(value[0], value[1][0])
            }
        });

        setIsLoading(true)

        try {
            const dataRes = await APIexcel.post(documentoForm)
            setTipoCuenta(dataRes.cuenta)
            setMovements(dataRes.movements)
            const tiposProv = []
            for (let index = 0; index < dataRes.tipos.length; index++) {
                const element = dataRes.tipos[index];
                tiposProv.push({ value: element.id, label: element.description })
            }
            setTiposProv(tiposProv)
        } catch (ex) {
            alert(ex)
        } finally {
            setIsLoading(false)
        }

    }

    const openDialogSaveConfirmation = () => {
        setSaveDialog(true)
    }

    // Salvamos lista de movimientos
    const saveMovimientos = async (confirm) => {
        if (checkIfValid() && confirm) {
            await APImovimientos.saveMovimientos({ facturas: movements })
            navigate("/movimientos");
        } else {
            setSaveDialog(false)
        }
    }

    const changeValueProv = (indexRowMovement, prov) => {
        let jsonNew = [].concat(movements)
        jsonNew[indexRowMovement]["cliente"] = prov
        if (prov && prov.data) {
            jsonNew[indexRowMovement]["cif"] = prov.data.cif
        }
        jsonNew[indexRowMovement]["saved"] = false
        setMovements(jsonNew)
        return
    }

    const changeCif = (indexRowMovement, newCif) => {
        let jsonNew = [].concat(movements)
        jsonNew[indexRowMovement]["cif"] = newCif
        jsonNew[indexRowMovement]["saved"] = false

        setMovements(jsonNew)
        return
    }

    const changeTipo = (indexRowMovement, newTipo) => {
        let jsonNew = [].concat(movements)
        jsonNew[indexRowMovement]["tipo"] = newTipo
        setMovements(jsonNew)
        return
    }

    const changeIva = (indexRowMovement, newIva) => {
        let jsonNew = [].concat(movements)

        let total = jsonNew[indexRowMovement]["total"]

        jsonNew[indexRowMovement]["iva"] = newIva
        jsonNew[indexRowMovement]["base"] = total * 1.0 / (1 + newIva.value / 100.0)
        setMovements(jsonNew)
        return
    }

    const changeJson = (indexRowMovement, propierty, value) => {
        let jsonNew = [].concat(movements)

        jsonNew[indexRowMovement][propierty] = Number(value)
        setMovements(jsonNew)
        return
    }

    const checkIfValid = () => {

        for (let index = 0; index < movements.length; index++) {
            const element = movements[index];
            if (!element.cliente) {
                return false
            }
            if (Number(element.base).toFixed(2) !== (Number(element.total.toFixed(2)) / (1 + element.iva.value / 100.0)).toFixed(2)) {
                return false
            }
        }
        return true
    }

    const openDialogSaveProv = (index) => {
        let movimiento = movements[index]
        movimiento.index = index
        setRowSelected(movimiento)
    }

    const saveProveedorRelacion = async (confirm) => {

        if (confirm) {
            let body = {
                "original": rowSelected.cliente_original,
                "nombre": rowSelected.cliente.label,
                "cif": rowSelected.cif,
                "id": rowSelected.cliente.data ? rowSelected.cliente.data.id : null
            }

            await APIproveedoresRelationships.post(body)
        }

        let jsonNew = [].concat(movements)
        jsonNew[rowSelected.index]["saved"] = true
        setMovements(jsonNew)
        setRowSelected(null)
    }

    useEffect(() => {
        setIsDisabled(!checkIfValid())
    }, [movements])

    const isChanged = (movement) => {
        if (movement.cliente) {
            if (movement.cliente_map) {
                return !movement.saved && (movement.cliente.label !== movement.cliente_map.nombre || movement.cif !== movement.cliente_map.cif)
            } else {
                return !movement.saved && (movement.cliente.label !== movement.cliente_original || movement.cif)
            }
        }else{
            return false;
        }
    }

    /**
     * las columnas para la tabla
     * @param {*} movements 
     * @returns 
     */
    const getRows = (movements) => {

        return movements.map((movement, index) => {
            const options = [{ value: 0, label: movement.cliente_original, data: {} }]
            if (movement.cliente_map) {
                options.push({ value: index + 1, label: movement.cliente_map.nombre, data: movement.cliente_map })
            }
            if (movement.posibles.length) {
                for (let index = 0; index < movement.posibles.length; index++) {
                    const element = movement.posibles[index];
                    options.push({ value: index + 1, label: element.nombre, data: element })
                }
            }
            return <tr key={`mov-${index}`}>

                <td style={{ width: "20%" }}><CreatableSelect isClearable value={movement.cliente} onChange={(value) => { changeValueProv(index, value) }} defaultValue={options[0]} options={options} /></td>
                <td style={{ width: "15%" }}>
                    <input type="text" onChange={(e) => { changeCif(index, e.target.value) }} value={movement.cif} />
                    {isChanged(movement) && <button disabled={!isChanged(movement)} onClick={() => { openDialogSaveProv(index) }}><img src={save} alt="save-disk" style={{ height: "36px" }} /></button>}
                </td>
                <td style={{ width: "5%", fontSize: "115%" }}>{format(new Date(movement.fecha), 'dd/MM/yyyy')}</td>
                <td style={{ width: "20%" }}><Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={tiposProv[0]}
                    value={movement.tipo}
                    onChange={(e) => { changeTipo(index, e) }}
                    name="color"
                    options={tiposProv}
                /></td>
                <td style={{ width: "10%" }}><Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={tiposIva[2]}
                    value={movement.iva}
                    onChange={(e) => { changeIva(index, e) }}
                    name="color"
                    options={tiposIva}
                /></td>
                <td style={{ width: "10%", fontSize: "115%" }}>
                    <input type="text" onChange={(e) => { changeJson(index, "base", e.target.value) }} value={movement.base} /></td>
                <td style={{ width: "5%", fontSize: "115%" }}>{(movement.total - movement.base).toFixed(2)}</td>
                <td style={{ width: "10%", fontSize: "115%" }}>
                    <input type="text" onChange={(e) => { changeJson(index, "total", e.target.value) }} value={movement.total} /></td>
            </tr>
        })
    }
    return (
        <div className="w-100">
            {saveDialog ? <ConfirmModal title="¿Estás seguro de quere guardar estos movimientos bancarios?" onConfirmAction={(confirm) => { saveMovimientos(confirm) }} onClose={() => { setSaveDialog(false) }} description={`¿Estás seguro de querer guardar estos movimientos e introducirlos en nuestra base de datos?`} state={saveDialog} /> : null}
            {rowSelected != null && <ConfirmModal title="¿Quieres guardar esta relación?" onConfirmAction={(confirm) => { saveProveedorRelacion(confirm) }} onClose={() => { setRowSelected(null) }} description={`¿Deseas guardar la relación entre ${rowSelected.cliente_original} y ${rowSelected.cliente.label} con cif ${rowSelected.cif}? Esto hará que la próxima vez ya se cargue esta información cuando venga el nombre de ${rowSelected.cliente_original}`} state={rowSelected != null} />}
            {movements.length === 0 ? <><h1>Subir excel de proveedores</h1>
                <p>Adjunta el excel bancario</p>
                <br></br>
                <div className="w-100 d-flex flex-wrap flex-md-nowrap" style={{ maxWidth: "500px" }}>
                    <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="w-100 d-flex flex-column mt-2">
                            <div className="form-group">
                                <label htmlFor="documento">Excel a subir</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    {...register("documento", { required: true, maxLength: 255 })}
                                    defaultValue={''} />

                                <div className="form-invalid">
                                    {(errors.documento) && "El documento es obligatorio!"}
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex justify-content-end mt-2">
                            <button type="submit" disabled={loading} className="btn btn-primary">Procesar</button>
                        </div>
                    </form>
                </div></> : null}

            {movements.length ?
                <div className="w-100">
                    <h1>Revisa los movimientos bancarios</h1>
                    <p>El documento pertenece a la {tipoCuenta}. Si cambias el nombre de un cliente o su cif aparecerá un botón para poder guardar ese cambio y así automatizarlo la próxima vez.</p>
                    <div className="w-100 d-flex justify-content-start mt-2">
                        <button type="submit" onClick={() => { openDialogSaveConfirmation() }} disabled={isDisabled} className="btn btn-primary">Guardar movimientos</button>
                    </div>
                    <div className="w-100 mb-3">
                        <div className="table-responsive"> <table className="table">
                            <thead>
                                <tr>
                                    <th>Cliente (nombre original)</th>
                                    <th>CIF</th>
                                    <th>Fecha</th>
                                    <th>Tipo de factura</th>
                                    <th className="text-center">TIPO IVA</th>
                                    <th className="text-center">Base</th>
                                    <th className="text-center">IVA</th>
                                    <th className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getRows(movements)}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div> : null
            }
        </div>


    );
}