import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { format } from 'date-fns';
import { useForm } from "react-hook-form";
import { APIIncidencias } from "../../../../../../services/api/facturas";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function IncidenciaDialog({
    movimiento,
    onConfirmAction,
    onClose,
    updateMov,
    state
}) {
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm();

    const [incidencias, setIncidencias] = useState([]);

    const handleConfirm = (confirm) => {
        onConfirmAction(confirm);
    };

    const handleClose = () => {
        onClose();
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right',
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right',
        });
    };

    useEffect(() => {
        APIIncidencias.get(movimiento.prov_map_proveedor_id).then((data) => {
            setIncidencias(data);
            showToast('Obtenidos datos de movimientos');
        }).catch((err) => {
            showErrorToast('An error occurred while fetching data. Please try again later.');
        });
    }, []);

    const submit = async (data, e) => {
        e.preventDefault();
        const documentoForm = new FormData();

        documentoForm.append("prov_map_proveedor_id", movimiento.prov_map_proveedor_id);
        documentoForm.append("asunto", data.asunto);
        documentoForm.append("fecha", data.fecha);
        documentoForm.append("description", data.description);

        APIIncidencias.saveIncidencia(documentoForm).then((res, error, error2) => {
            const dataRes = res.incidencia;
            setIncidencias(dataRes);
            updateMov(movimiento.index, movimiento);
            showToast('Incidencia guardada correctamente.');
        }).catch((err) => {
            if (err.code === "ERR_BAD_REQUEST") {
                showErrorToast('Importes inválidos');
            }
        });
    };

    const getRows = (incidencias) => {
        return incidencias.map((incidencia, index) => {
            return (
                <tr key={`mov-${index}`}>
                    <td style={{ width: "5%", fontSize: "110%" }}>{format(new Date(incidencia.fecha), 'dd/MM/yyyy')}</td>
                    <td style={{ width: "10%", fontSize: "110%" }}>{incidencia.asunto}</td>
                    <td style={{ width: "10%", fontSize: "110%" }}>{incidencia.description}</td>
                </tr>
            );
        });
    };

    return (
        <Dialog
            open={state}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fw-bold">Incidencia - {movimiento.nombre}</p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    Introduce los datos de la incidencia para el movimiento de {movimiento.nombre} a fecha {format(new Date(), 'dd/MM/yyyy')}
                    <form className="form col-12 mb-4" onSubmit={handleSubmit(submit)}>
                        <ToastContainer />
                        <div className="w-100 d-flex flex-column mt-2">
                            <div className="form-group">
                                <div className="form-invalid">
                                    {(errors.documento) && "El documento es obligatorio!"}
                                </div>
                                <hr />
                            </div>
                            <div className="form-group">
                                <label htmlFor="subject">Asunto</label>
                                <input id="subject" type="text" {...register("asunto", { required: true })} />
                                <div className="form-invalid">
                                    {(errors.numero) && "Introduce un asunto para la incidencia"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label style={{ marginTop: "12px" }} htmlFor="fecha">Fecha Incidencia</label>
                                <input id="fecha" type="text" {...register("fecha", { required: true })} placeholder="dd/mm/yyyy" />
                                <div className="form-invalid">
                                    {(errors.date) && "Introduce una fecha para la incidencia"}
                                </div>
                            </div>
                            <div className="form-group">
                                <label style={{ marginTop: "12px" }} htmlFor="description">Descripción de la incidencia</label>
                                <textarea id="description" type="text" {...register("description", { required: true })} />
                                <div className="form-invalid">
                                    {(errors.total) && "Introduce un descrpción para la incidencia!"}
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 table-responsive" style={{ maxHeight: "200px", overflowY: "auto" }}>
                            <table className="mt-3 table">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Asunto</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {incidencias && incidencias.length ? getRows(incidencias) : <tr><td colSpan="3">No hay incidencias registradas</td></tr>}
                                </tbody>
                            </table>
                        </div>

                        <div className="w-100 d-flex justify-content-end mt-2">
                            <button type="submit" className="btn btn-primary">Procesar</button>
                        </div>
                    </form>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}