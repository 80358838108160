
import { Route, Routes, Navigate } from "react-router-dom";

import Inicio from './components/Inicio';
import MovsView from './components/Movimientos';
import FactsView from './components/Facturas';
import AbonosView from './components/Abonos';
import DashBoardView from './components/Dashboard';
import MicuentaView from './components/Micuenta';
import Config from "./components/shared/components/Config";
import Navbar from "./components/shared/components/Navbar";
import BackOfficeView from './components/Backoffice';


const Main = ({onLogout, usuario}) => {

      return (
        <div className="dashboard-container">
            <Navbar onLogout={onLogout} usuario={usuario} />

            <Config />

         
            <Routes>
                <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                <Route path="/" exact element={<MovsView />}></Route>
                <Route path="/cargarMovimientos" exact element={<Inicio />}></Route>
                <Route path="/movimientos" element={<MovsView />}></Route>
                <Route path="/facturas" element={<FactsView />}></Route>
                <Route path="/abonos" element={<AbonosView />}></Route>
                <Route path="/exportadores" element={<DashBoardView />}></Route>
                <Route path="/backoffice" element={<BackOfficeView />}></Route>
                <Route path="/mi-cuenta" element={<MicuentaView />}></Route>
            </Routes>

        </div> 
    );
}

/**

 */

export default Main