import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { changePassword } from "../../../../services/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Micuenta({ setToken }) {
    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem("user_fact")) || {};

    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        // Retrieve num_empleado and password from userData
        const { num_empleado, password } = userData;

        const response = await changePassword({
            num_empleado,
            password: newPassword,
        });

        if (response && response.success) {
            // Assuming setToken is a function to update the token in your state
            localStorage.setItem("user_fact", JSON.stringify(response.user));
            showToast('Password cambiado correctamente');
        }
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    return (
        <div className="content pt-3">
            <ToastContainer />
            <h1>Mi cuenta</h1>
            <p className="mb-2">Aquí aparecen los datos de tu cuenta y puedes cambiar las credenciales</p>

            <fieldset className="export-fieldset">
                <legend>Mis datos</legend>
                <div className="flex flex-row mb-4 space-x-2" style={{ paddingTop: "1em" }}>
                    <label>Nombre:</label>
                    <span style={{ marginRight: "1rem" }}>{userData.nombre}</span>
                    <label>Número de empleado:</label>
                    <span style={{ marginRight: "1rem" }}>{userData.num_empleado}</span>
                    <label>Email:</label>
                    <span style={{ marginRight: "1rem" }}>{userData.email}</span>
                </div>
            </fieldset>

            <fieldset className="export-fieldset">
                <legend>Cambiar credenciales</legend>
                <div className="flex flex-row mb-4 space-x-2" style={{ paddingTop: "1em" }}>
                    <label>Nuevo password:</label>
                    <input
                        type="password"
                        style={{ marginRight: "1rem" }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <label>Repetir password:</label>
                    <input
                        type="password"
                        style={{ marginRight: "1rem" }}
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    <button className="btn btn-primary" onClick={handlePasswordChange}>
                        Cambiar Contraseña
                    </button>
                </div>
            </fieldset>
        </div>
    );
}

Micuenta.propTypes = {
    setToken: PropTypes.func.isRequired,
};
