import React from "react";
import Step1 from "./components/AdjuntarFacturas";

export default function Inicio({
}) {
    return (
        <div className="content">
            <Step1></Step1>
        </div>
    );
}