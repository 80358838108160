import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { React } from "react";
import { useForm } from "react-hook-form";
import { APImovimientos } from "../../../../../../services/api/facturas";


export default function NotaDialog({
    movimiento,
    onConfirmAction,
    onClose,
    updateMov,
    state
}) {
    const {
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit
    } = useForm({});

    const colorValue = watch("color");

    const handleConfirm = (confirm) => {
        onConfirmAction(confirm);
    };

    const handleClose = () => {
        onClose();
    };

    const handleEliminarColor = () => {
        // Esta función se llama al hacer clic en el botón para eliminar el color
        setValue('color', ''); // Establece el valor del campo "color" a una cadena vacía
    };

    const submit = async (data) => {
        const documentoForm = {
            id: movimiento.id,
            prov: movimiento.prov_map_proveedor_id,
            nota: data.nota,
            color: data.color,
        };

        try {
            const response = await APImovimientos.performNota(documentoForm);

            const dataRes = response;
            updateMov(movimiento.index, dataRes.movimiento);
        } catch (err) {
            console.error("Error:", err);

            if (err.response && err.response.status === 400) {
                alert("No se ha podido introducir la nota");
            }
        }
    };

    return (
        <Dialog
            open={state}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fw-bold">{movimiento.nombre}</p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    Cambia el color o añade una nota a este movimiento
                    <form className="form col-12 mb-4" onSubmit={handleSubmit(submit)}>
                        <div className="w-100 d-flex flex-column mt-2">
                            <div className="form-group">
                                <label>Elige un color para la nota:</label>
                                <input
                                    type="text"
                                    {...register("color")}
                                    list="coloresList"
                                    defaultValue={movimiento.color}
                                />
                                {colorValue && ( // Muestra el botón solo si hay un color seleccionado
                                    <button type="button" onClick={handleEliminarColor}>
                                        Eliminar Color
                                    </button>
                                )}
                                <datalist id="coloresList">
                                    <option value="#FFFF00">Amarillo</option>
                                    <option value="#0000FF">Azul</option>
                                </datalist>
                            </div>
                            <div className="form-group">
                                <label htmlFor="nombre">Nota</label>
                                <input id="nota" type="text" {...register("nota", { required: true })} size={120} defaultValue={movimiento.nota} />
                                <div className="form-invalid">
                                    {errors.nota && "Introduce una nota"}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-end mt-2">
                            <button type="submit" className="btn btn-primary">Guardar nota</button>
                        </div>
                    </form>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}