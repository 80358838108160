import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import AbonoViewer from '../../../ViewerAbono';

const FacturaViewerDialog = ({ abonoId, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Abono Viewer</DialogTitle>
      <DialogContent>
        <AbonoViewer abonoId={abonoId} />
      </DialogContent>
    </Dialog>
  );
};

export default FacturaViewerDialog;