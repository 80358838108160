import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { APIProveedores, APImovimientos } from "../../../../../../services/api/facturas";


export default function EditMovimientoDialog({
    movimiento,
    onConfirmAction,
    onClose,
    updateMov,
    state
}) {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm();

    const [tiposFacturas, setTiposFacturas] = useState([]);
    const [selectedTipoFactura, setSelectedTipoFactura] = useState();


    useEffect(() => {
        APIProveedores.get().then((data) => {
            setTiposFacturas(data.tiposProvs);
            setSelectedTipoFactura(movimiento.prov_map_tipo_mov_id);
        }).catch((err) => {

        })
    }, []);

    const handleConfirm = (confirm) => {
        onConfirmAction(confirm);
    };

    const handleClose = () => {
        onClose();
    };

    const handleChangeValue = (event) => {
        const newValue = event.target.value;
        setSelectedTipoFactura(newValue);
    };

    const handleChangeTipoIva = (event) => {
        movimiento.tipo_iva = event.target.value;
        setValue('tipo_iva', movimiento.tipo_iva);
        if (movimiento.tipo_iva === "21") {
            movimiento.base = (movimiento.total / 1.21).toFixed(2);
            setValue('base', movimiento.base);
        } else if (movimiento.tipo_iva === "10") {
            movimiento.base = (movimiento.total / 1.10).toFixed(2);
            setValue('base', movimiento.base);
        } else if (movimiento.tipo_iva === "0") {
            movimiento.iva = 0;
            movimiento.base = movimiento.total;
            setValue('base', movimiento.total);
        }
        movimiento.iva = (movimiento.total - movimiento.base).toFixed(2)
        setValue('iva', movimiento.iva);
        setValue('total', movimiento.total);
    };

    const setValuesBeforeSubmit = () => {
        setValue('tipo_iva', movimiento.tipo_iva);
        setValue('iva', movimiento.iva);
        setValue('total', movimiento.total);
        setValue('base', movimiento.base);
        setSelectedTipoFactura(movimiento.prov_map_tipo_mov_id);
    };

    const submit = async (data) => {
        const documentoForm = {};

        Object.entries(data).forEach((value) => {
            documentoForm[value[0]] = value[1];
        });

        documentoForm["id"] = movimiento.id;
        documentoForm["prov"] = movimiento.prov_map_proveedor_id;

        APImovimientos.put(documentoForm)
            .then((res, error, error2) => {
                const dataRes = res;
                updateMov(movimiento.index, dataRes.movimiento);
            })
            .catch((err) => {
                if (err.code === "ERR_BAD_REQUEST") {
                    alert("Importes inválidos");
                }
            });
    };

    return (
        <Dialog
            open={state}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description" maxWidth="lg">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fw-bold">{movimiento.nombre}</p>
            </DialogTitle>
            <DialogContent style={{ width: '50vw', maxWidth: 'none' }}>
                <DialogContentText id="confirm-description">
                    Modifica los datos del siguiente movimiento
                    <form className="form col-12 mb-4" onSubmit={(e) => {
                        e.preventDefault();
                        setValuesBeforeSubmit();
                        handleSubmit(submit)(e);
                    }}>
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="form-group">
                                <label htmlFor="nombre">Proveedor</label>
                                <input id="nombre" type="text" {...register("nombre", { required: true })} defaultValue={movimiento.nombre} />
                                <div className="form-invalid">
                                    {(errors.nombre) && "Introduce un nombre"}
                                </div>
                            </div>
                            <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                <label style={{ marginTop: "12px" }} htmlFor="cifnif">CIF/NIF</label>
                                <input id="cifnif" type="text" {...register("cifnif", { required: true })} defaultValue={movimiento.cifnif} />
                                <div className="form-invalid">
                                    {(errors.cifnif) && "Introduce un cifnif"}
                                </div>
                            </div>
                            <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                <label style={{ marginTop: "12px" }} htmlFor="tipo_iva">Tipo IVA</label>
                                <select id="tipo_iva" {...register("tipo_iva", { required: true })}
                                    defaultValue={movimiento.tipo_iva}
                                    onChange={handleChangeTipoIva}>
                                    <option value="">Selecciona el tipo de IVA</option>
                                    <option value="21">21%</option>
                                    <option value="10">10%</option>
                                    <option value="0">0%</option>
                                </select>
                                <div className="form-invalid">
                                    {(errors.tipo_iva) && "Selecciona un tipo de IVA"}
                                </div>
                            </div>
                            <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                <label style={{ marginTop: "12px" }} htmlFor="iva">IVA</label>
                                <input id="iva" type="text" {...register("iva", { required: true })} style={{ opacity: "0.5", cursor: "not-allowed" }} value={movimiento.iva} disabled />
                                <div className="form-invalid">
                                    {(errors.iva) && "Introduce un iva"}
                                </div>
                            </div>
                            <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                <label style={{ marginTop: "12px" }} htmlFor="base">Base</label>
                                <input id="base" type="text" {...register("base", { required: true })} style={{ opacity: "0.5", cursor: "not-allowed" }} value={movimiento.base} disabled />
                                <div className="form-invalid">
                                    {(errors.base) && "Introduce un base"}
                                </div>
                            </div>
                            <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                <label style={{ marginTop: "12px" }} htmlFor="total">Total</label>
                                <input id="total" type="text" {...register("total", { required: true })} style={{ opacity: "0.5", cursor: "not-allowed" }} value={movimiento.total} disabled />
                                <div className="form-invalid">
                                    {(errors.total) && "Introduce un total"}
                                </div>
                            </div>
                            <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                <label style={{ marginTop: "12px" }} htmlFor="prov_map_tipo_mov_id">Tipo de Factura</label>
                                <select
                                    id="prov_map_tipo_mov_id"
                                    {...register("prov_map_tipo_mov_id", { required: true })}
                                    defaultValue={movimiento.prov_map_tipo_mov_id}
                                    onChange={handleChangeValue}
                                    value={selectedTipoFactura}
                                >
                                    {/* Mapea los tipos de factura para crear las opciones del select */}
                                    {tiposFacturas.map((tipo) => (
                                        <option key={tipo.id} value={tipo.id}>
                                            {tipo.description}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-invalid">
                                    {(errors.prov_map_tipo_mov_id) && "Introduce un tipo de factura"}
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex justify-content-end mt-2">
                            <button type="submit" className="btn btn-primary">Guardar cambios</button>
                        </div>
                    </form>
                </DialogContentText>

            </DialogContent>
        </Dialog>
    );
}