import React from "react";
import MovimientosList from "./FacturasList";



export default function Facturas() {
    return (
        <div className="content">
            <MovimientosList />
        </div>
    );
}