import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import FacturaViewer from '../../../Viewer';

const FacturaViewerDialog = ({ facturaId, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Factura Viewer</DialogTitle>
      <DialogContent>
        <FacturaViewer facturaId={facturaId} />
      </DialogContent>
    </Dialog>
  );
};

export default FacturaViewerDialog;