import { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import React from "react";
import { APImovimientos } from "../../../../../services/api/facturas";
import Pagination from '@mui/material/Pagination';
import save from "../../../../../images/icon_folder.png";
import info from "../../../../../images/info.png";
import remove from "../../../../../images/delete.png";
import pending from "../../../../../images/icon_pending.png";
import done from "../../../../../images/icon_done.png";
import edit from "../../../../../images/icon_edit.png";
import write_nota from "../../../../../images/write_icon.png";
import edit_nota from "../../../../../images/noti_icon.png";
import FolderDialog from "./Dialogs/FolderDialog";
import EditMovimientoDialog from "./Dialogs/EditMovimientoDialog";
import IncidenciaDialog from "./Dialogs/IncidenciaDialog";
import NotaDialog from "./Dialogs/NotaDialog";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';
import ConfirmModal from "../../shared/components/ConfirmModal";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

/**
 * Lista de movimientos bancarios ya registrados
 * @param {*} param0 
 * @returns 
 */
export default function MovimientosList() {
    const limit = 15;
    const navigate = useNavigate();
    const [movimientos, setMovimientos] = useState([]);
    const [movimientosFiltered, setMovimientosFiltered] = useState([]);
    const [movimientoSelected, setMovimientoSelected] = useState(null);
    const [isModifyDialog, setModifyDialog] = useState(false);
    const [isFacturasDialog, setFacturasDialog] = useState(false);
    const [isNoteDialog, setIsNoteDialog] = useState(false);
    const [isIncidenciaDialog, setIsIncidenciaDialog] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [filters, setFilters] = useState(false);
    const currPage = parseInt(1);
    const [currentPage, setPage] = useState(currPage);
    const [sortColumn, setSortColumn] = useState(null); // Track which column is being sorted
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sorting order (ascending by default)
    // Estados para los valores seleccionados en los desplegables select
    const [selectedDesc, setSelectedDesc] = useState(null);
    const [selectedNombre, setSelectedNombre] = useState(null);
    const [selectedCifnif, setSelectedCifnif] = useState(null);
    const [selectedEstado, setSelectedEstado] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [deletingMovimiento, setDeletingMovimiento] = useState(null);
    const estados = [
        { value: "", label: "Filtrar por Estado" },
        { value: ">0", label: "Pendientes" },
        { value: "0", label: "Finalizadas" },
    ];
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        setDateRange(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleReset = () => {
        setDateRange({
            startDate: '',
            endDate: ''
        });
    };

    const handleDeleteClick = (index, movimiento) => {
        setDeletingIndex(index);
        setDeletingMovimiento(movimiento);
        setConfirmationModalOpen(true);
    };

    const handleConfirmDelete = async (confirm) => {
        setConfirmationModalOpen(false);

        if (confirm) {
            try {
                await APImovimientos.delete(deletingMovimiento.id);
                showToast('Se ha eliminado correctamente el movimiento');
                fetchMovimientos();
            } catch (error) {
                showErrorToast('No se ha podido eliminar el movimiento, pruebe más tarde');
            }
        } else {
            // No hacer nada si el usuario ha cancelado
        }
    };

    const handleCancelDelete = () => {
        setConfirmationModalOpen(false);
    };

    useEffect(() => {
        fetchMovimientos();
    }, [selectedDesc, selectedNombre, selectedCifnif, selectedEstado, dateRange]);

    const fetchMovimientos = () => {
        const ftr = [];
        if (selectedDesc != null && selectedDesc != '') {
            ftr.push("description|" + selectedDesc.description);
        }
        if (selectedNombre != null && selectedNombre != '') {
            ftr.push("nombre|" + selectedNombre.nombre);
        }
        if (selectedCifnif != null && selectedCifnif != '') {
            ftr.push("cifnif|" + selectedCifnif.cifnif);
        }
        if (selectedEstado != null && selectedEstado != '') {
            ftr.push("restante|" + selectedEstado);
        }
        if (dateRange.startDate && dateRange.endDate) {
            ftr.push("cierre|1");
            ftr.push('created_at|>=' + dateRange.startDate)
            ftr.push('created_at|<=' + dateRange.endDate)
        }else{
            ftr.push("cierre|IS NULL");
        }
        APImovimientos.post({ limit, currentPage, ftr })
            .then((data) => {
                setMovimientos(data.movimientos);
                setMovimientosFiltered(data.movimientos);
                setPageCount(data.count);
                sanitizeFilters(data.filters);
                showToast('Obtenidos datos de movimientos');
            })
            .catch((err) => {
                // Handle errors
                showErrorToast('An error occurred while fetching data. Please try again later.');
            });
    };

    const sanitizeFilters = (filters) => {
        filters.dDesc[1].pop()
        filters.dNombre[1].pop()
        filters.dCifnif[1].pop()
        setFilters(filters);
    };

    const handleCargarMovimientos = () => {
        navigate('/cargarMovimientos');
    };

    const showToast = (message) => {
        toast.info(message, {
            toastId: "info-movs",
            position: 'top-right', // You can customize the position
        });
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    const ColumnHeader = ({ title, onFilter, onSort, sortOrder }) => {
        const inputRef = useRef(null);
        const handleSortChange = () => {
            onSort(title);
        };

        return (
            <th>
                <div>
                    <span>{title}</span>
                    <span style={{ cursor: 'pointer' }} onClick={handleSortChange}>
                        &nbsp;{sortOrder === 'asc' ? '▲' : '▼'}
                    </span>
                </div>
            </th>
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const ftr = [];
        if (selectedDesc != null && selectedDesc != '') {
            ftr.push("description|" + selectedDesc.description);
        }
        if (selectedNombre != null && selectedNombre != '') {
            ftr.push("nombre|" + selectedNombre.nombre);
        }
        if (selectedCifnif != null && selectedCifnif != '') {
            ftr.push("cifnif|" + selectedCifnif.cifnif);
        }
        if (selectedEstado != null && selectedEstado != '') {
            ftr.push("restante|" + selectedEstado);
        }
        if (dateRange.startDate && dateRange.endDate) {
            ftr.push("cierre|1");
            ftr.push('created_at|>=' + dateRange.startDate)
            ftr.push('created_at|<=' + dateRange.endDate)
        }else{
            ftr.push("cierre|IS NULL");
        }
        const ftrParams = ftr.map(filter => `filter=${filter}`).join('&');
        APImovimientos.get(limit, newPage, ftrParams).then((data) => {
            setMovimientos(data.movimientos);
        }).catch((err) => {

        })
    }
    const openNotaDialog = (index, movimiento) => {
        movimiento.index = index
        setMovimientoSelected(movimiento)
        setIsNoteDialog(true)
    }

    const openIncidenciaDialog = (index, movimiento) => {
        movimiento.index = index
        setMovimientoSelected(movimiento)
        setIsIncidenciaDialog(true)
    }

    const openEditDialog = (index, movimiento) => {
        movimiento.index = index
        setMovimientoSelected(movimiento)
        setModifyDialog(true)
    }

    const openFolderDialog = (index, movimiento) => {
        movimiento.index = index
        setMovimientoSelected(movimiento)
        setFacturasDialog(true)
    }

    const updateMovimiento = (index, movimiento) => {
        let movs = [].concat(movimientos)
        movs[index] = movimiento
        setMovimientos(movs);

    }

    const updateMovimientoAndClose = (index, movimiento) => {
        let movs = [].concat(movimientos)
        movs[index] = movimiento
        setMovimientos(movs);
        setModifyDialog(false)
        setMovimientoSelected(null)
        fetchMovimientos();
    }

    function flattenArray(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return arr.reduce((acc, val) => acc.concat(val), []);
    }

    const handleFilterByEstado = (value) => {
        setFilters({
            ...filters,
            estado: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'estado' with the actual property name from your data
            const estadoValue = movimiento.id.toString().toLowerCase();
            return estadoValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByFecha = (value) => {
        setFilters({
            ...filters,
            fecha: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'fecha' with the actual property name from your data
            const fechaValue = movimiento.fecha.toString().toLowerCase();
            return fechaValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByNombre = (value) => {
        setFilters({
            ...filters,
            nombre: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'nombre' with the actual property name from your data
            const nombreValue = movimiento.nombre.toString().toLowerCase();
            return nombreValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByCifNif = (value) => {
        setFilters({
            ...filters,
            cifonif: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'cifnif' with the actual property name from your data
            const cifnifValue = movimiento.cifnif.toString().toLowerCase();
            return cifnifValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByTipoFactura = (value) => {
        setFilters({
            ...filters,
            tipodefactura: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'tipoFactura' with the actual property name from your data
            const tipoFacturaValue = movimiento.description.toString().toLowerCase();
            return tipoFacturaValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByBase = (value) => {
        setFilters({
            ...filters,
            base: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'base' with the actual property name from your data
            const baseValue = movimiento.base.toString().toLowerCase();
            return baseValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByIva = (value) => {
        setFilters({
            ...filters,
            iva: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'iva' with the actual property name from your data
            const ivaValue = movimiento.iva.toString().toLowerCase();
            return ivaValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByTotal = (value) => {
        setFilters({
            ...filters,
            total: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'total' with the actual property name from your data
            const totalValue = movimiento.total.toString().toLowerCase();
            return totalValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };
    const handleFilterByRestante = (value) => {
        setFilters({
            ...filters,
            restante: value,
        });
        // Create a copy of the original movimientos array
        const filteredMovimientos = [...movimientos];
        // Apply the filter to the copy
        const filteredMovimientosResult = filteredMovimientos.filter((movimiento) => {
            // Replace 'restante' with the actual property name from your data
            const restanteValue = movimiento.restante.toString().toLowerCase();
            return restanteValue.includes(value.toLowerCase());
        });
        // Set the filtered copy as the state
        setMovimientosFiltered(filteredMovimientosResult);
    };

    const handleSortByColumn = (column) => {
        if (column === sortColumn) {
            // Toggle sorting order if the same column is clicked again
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // Sort by the clicked column in ascending order by default
            setSortColumn(column);
            setSortOrder('asc');
        }

        // Implement sorting logic based on 'column' and 'sortOrder'
        // You can use the sorted data for rendering your table
        const sortedMovimientos = movimientos; // Create a copy of the array to avoid mutating the original data
        sortedMovimientos.sort((a, b) => {
            column = column.toString().toLowerCase();
            //excepts
            if (column === 'tipo de factura') {
                column = 'description';
            }
            if (column === 'cif o nif') {
                column = 'cifnif';
            }
            const columnA = a[column];
            const columnB = b[column];

            // Perform null or undefined check before accessing properties
            if (columnA == null || columnB == null) {
                // Handle null or undefined values as needed
                return 0;
            }

            if (typeof columnA === 'string') {
                // Handle string comparison
                if (sortOrder === 'asc') {
                    return columnA.localeCompare(columnB);
                } else {
                    return columnB.localeCompare(columnA);
                }
            } else if (typeof columnA === 'number') {
                // Handle numeric comparison
                if (sortOrder === 'asc') {
                    return columnA - columnB;
                } else {
                    return columnB - columnA;
                }
            } else if (columnA instanceof Date || typeof columnA === 'number') {
                // Handle date or time value comparison
                if (sortOrder === 'asc') {
                    return columnA - columnB;
                } else {
                    return columnB - columnA;
                }
            } else {
                // Handle other data types or custom comparison logic
                // Add custom comparison logic here if needed
                return 0; // Default to no change in order
            }
        });
        setMovimientosFiltered(sortedMovimientos);
    };

    const getRows = (listMovimientos) => {

        return listMovimientos.map((movement, index) => {
            return <tr className={movement.color ? 'bookmark' : ''} style={{ borderLeftColor: movement.color, color: movement.restante === 0 ? "darkgreen" : undefined }}
                key={`mov-${index}`}>
                <td style={{ width: "3%", fontSize: "110%" }}>
                    <img onClick={() => { openIncidenciaDialog(index, movement) }} src={movement.restante === 0 ? done : pending} alt="status-mov" style={{ height: "36px", cursor: "pointer" }}></img>
                    <Tooltip
                        title={movement.nota || ''}
                        arrow
                        placement="right"
                        sx={{ fontSize: "30" }}
                        slotProps={{
                            tooltip: {
                                sx: {
                                    color: "#514E6A",
                                    backgroundColor: "#ffff",
                                },
                            },
                        }}
                    >
                        <span>
                            <button onClick={() => { openNotaDialog(index, movement) }}><img src={movement.restante === 0 ? write_nota : edit_nota} alt="nota-icon" style={{ height: "28px" }} /></button>
                        </span>
                    </Tooltip>
                </td>
                <td style={{ width: "5%", fontSize: "110%" }}>{format(new Date(movement.fecha), 'dd/MM/yyyy')}</td>
                <td style={{ width: "10%", fontSize: "110%" }}>{movement.nombre}</td>
                <td style={{ width: "10%", fontSize: "110%" }}>{movement.cifnif}</td>
                <td style={{ width: "10%", fontSize: "110%" }}>{movement.description}</td>
                <td style={{ width: "5%", fontSize: "110%" }}>{movement.base}</td>
                <td style={{ width: "5%", fontSize: "110%" }}>{movement.iva}</td>
                <td style={{ width: "5%", fontSize: "110%" }}>{movement.total}</td>
                <td style={{ width: "5%", fontSize: "110%" }}>{movement.restante}</td>
                <td style={{ width: "5%", fontSize: "110%" }}>
                    <button onClick={() => { openEditDialog(index, movement) }}><img src={edit} alt="edit-icon" style={{ height: "36px", marginRight: "4px" }} /></button>
                    <button onClick={() => { openFolderDialog(index, movement) }}><img src={save} alt="save-disk" style={{ height: "36px" }} /></button>
                    <Tooltip
                        title={movement.obs || ''}
                        arrow
                        placement="left"
                        sx={{ fontSize: "30" }}
                        slotProps={{
                            tooltip: {
                                sx: {
                                    color: "#514E6A",
                                    backgroundColor: "#ffff",
                                },
                            },
                        }}
                    >
                        <span>
                            <img src={info} alt="info-icon" style={{ height: "36px" }} />
                        </span>
                    </Tooltip>
                    {movement.tiene_facturas === 0 && (
                        <>
                            <button onClick={() => handleDeleteClick(index, movement)}>
                                <img src={remove} alt="remove" style={{ height: "36px" }} />
                            </button>

                            {/* Modal de confirmación */}
                            {confirmationModalOpen && (
                                <ConfirmModal
                                    title="Confirmar eliminación"
                                    description="¿Seguro que desea eliminar el movimiento?"
                                    state={confirmationModalOpen}
                                    onConfirmAction={handleConfirmDelete}
                                    onClose={handleCancelDelete}
                                />
                            )}
                        </>
                    )}
                </td>
            </tr>
        })
    }

    const customWidth = {
        width: '18%',
        display: 'inline-block',
        paddingRight: '10px'
    };

    return (
        <div className="content pt-3">
            <ToastContainer />
            <h1>Lista de movimientos bancarios</h1><button className="btn btn-primary" style={{ top: "6rem", right: "4rem", width: "20%", position: "fixed" }} onClick={handleCargarMovimientos}>Cargar Movimientos</button>
            <div className="flex flex-row mb-4 space-x-2" style={{ paddingTop: "1em" }}>
                <div className="flex flex-col flex-1" style={customWidth}>
                    <Autocomplete
                        value={estados.find((option) => option.value === selectedEstado) || estados[0]}
                        options={estados}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                            setSelectedEstado(newValue.value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Filtrar por Estado"
                                variant="outlined" />
                        )}
                        disableClearable
                    />
                </div>

                <div className="flex flex-col flex-1" style={customWidth}>
                    <Autocomplete
                        value={selectedDesc}
                        options={flattenArray(filters.dDesc)}
                        getOptionLabel={(option) => option?.description || ""}
                        onChange={(event, newValue) => {
                            setSelectedDesc(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Filtrar por Tipo.."
                                variant="outlined"
                            />
                        )}
                    />
                </div>

                <div style={customWidth} className="flex flex-col flex-1">
                    <Autocomplete
                        value={selectedNombre}
                        options={flattenArray(filters.dNombre)}
                        getOptionLabel={(option) => option?.nombre || ""}
                        onChange={(event, newValue) => {
                            setSelectedNombre(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Filtrar por Nombre.."
                                variant="outlined" />
                        )}
                    />
                </div>

                <div style={customWidth} className="flex flex-col flex-1">
                    <Autocomplete
                        value={selectedCifnif}
                        options={flattenArray(filters.dCifnif)}
                        getOptionLabel={(option) => option?.cifnif || ""}
                        onChange={(event, newValue) => {
                            setSelectedCifnif(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Filtrar por CIF/NIF"
                                variant="outlined" />
                        )}
                    />
                </div>
                <div style={{ display: 'inline-block', flexDirection: 'column', width: customWidth }} className="flex flex-col flex-1">
                    <input style={{ verticalAlign: 'text-top' }} type="date" id="date-range" name="startDate" value={dateRange.startDate} onChange={handleDateChange} />
                    <input style={{ verticalAlign: 'text-top' }} type="date" name="endDate" value={dateRange.endDate} onChange={handleDateChange} />
                    <button onClick={handleReset}>Reset</button>
                </div>
            </div>
            <div className="w-100 facturas-list-item">
                {movimientoSelected && isFacturasDialog && <FolderDialog updateMov={updateMovimiento} state={isFacturasDialog} movimiento={movimientoSelected} onConfirmAction={() => { }} onClose={() => { setMovimientoSelected(null); setFacturasDialog(false) }} />}
                {movimientoSelected && isModifyDialog && <EditMovimientoDialog updateMov={updateMovimientoAndClose} state={isModifyDialog} movimiento={movimientoSelected} onConfirmAction={() => { }} onClose={() => { setMovimientoSelected(null); setModifyDialog(false) }} />}
                {movimientoSelected && isNoteDialog && <NotaDialog updateMov={updateMovimientoAndClose} state={isNoteDialog} movimiento={movimientoSelected} onConfirmAction={() => { }} onClose={() => { setMovimientoSelected(null); setIsNoteDialog(false); }} />}
                {movimientoSelected && isIncidenciaDialog && <IncidenciaDialog updateMov={updateMovimientoAndClose} state={isIncidenciaDialog} movimiento={movimientoSelected} onConfirmAction={() => { }} onClose={() => { setMovimientoSelected(null); setIsIncidenciaDialog(false); }} />}
                <div className="table-responsive">
                    <table className="mt-3 table">
                        <thead>
                            <tr>
                                <ColumnHeader
                                    title="Estado"
                                    onFilter={handleFilterByEstado}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Estado' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Fecha"
                                    onFilter={handleFilterByFecha}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Fecha' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Nombre"
                                    onFilter={handleFilterByNombre}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Nombre' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Cif o nif"
                                    onFilter={handleFilterByCifNif}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Cif o nif' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Tipo de factura"
                                    onFilter={handleFilterByTipoFactura}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Tipo de factura' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Base"
                                    onFilter={handleFilterByBase}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Base' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Iva"
                                    onFilter={handleFilterByIva}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Iva' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Total"
                                    onFilter={handleFilterByTotal}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Total' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Restante"
                                    onFilter={handleFilterByRestante}
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Restante' ? sortOrder : null}
                                />
                                <ColumnHeader
                                    title="Facturas"
                                    onSort={handleSortByColumn}
                                    sortOrder={sortColumn === 'Facturas' ? sortOrder : null}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {movimientos.length ? getRows(movimientos) : <></>}
                        </tbody>
                    </table>
                    <Pagination
                        className="mt-3"
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                    />
                </div>
            </div>
        </div>
    );
}