import { useState } from 'react';

export default function useToken() {
  const [token, setToken] = useState(getToken());

  function getToken() {
    const currentUser = JSON.parse(localStorage.getItem('user_fact'));
    return currentUser?.token;
  }

  function saveToken(user) {
    if(user) {
      localStorage.setItem('user_fact', JSON.stringify(user));
      setToken(user.token);
    } else {
      localStorage.removeItem('user_fact');
      setToken(null);
    }
  }

  return {
    setToken: saveToken,
    token
  }
}